<div fxLayout="column" [style.filter]="showLoader == true ? 'blur(0.2rem)' : 'none'" fxFill>
    <div fxLayout="column">
        <div fxLayout="row" class="information-header" fxLayoutAlign="space-between center">
            <div fxLayout="column">{{eventName}} <ng-container *ngIf="appService.eventDetails?.length > 1">({{eventInfo}})</ng-container></div>
            <div fxLayout="column">{{selectedDate | date:'dd.MM.YYYY'}}</div>
        </div>
        <div class="check-in-header" fxLayout="row" fxLayoutAlign="end center">
            <div fxLayout="column">
                <p> Anmeldungen: {{appService.participantList?.[eventId]?.[selectedDate]?.length ?? 0}} | Check-ins: {{appService.checkedParticipantList?.[eventId]?.[selectedDate]?.length ?? 0}}</p>
            </div>
        </div>
    </div>
    <div class="default-layout-style double-toolbar" fxLayout="column" fxFill style="margin-top: 40px;">
        <div>
            <dx-button class="button-scan-participant" [height]="'85px'" [width]="'100%'"
                       text="{{numberOfDays.length > 1 ? 'Teilnehmer einchecken (' + (selectedDate | date:'dd.MM.') + ')' : 'Teilnehmer einchecken'}}"
                       type="default" icon="assets/images/qrcode.svg " (onClick)="getParticipantScanner()">
            </dx-button>
        </div>
        <div>
          <dx-button class="button" [height]="'auto'" [width]="'100%'" style="margin-top: 15px;"
                     type="default" text="Sortierung umkehren" (onClick)="reorder()">
          </dx-button>
        </div>
        <dx-list class="workshop-participant-list" [dataSource]="eventUsersList" [searchEnabled]="true"
            [pageLoadMode]="'scrollBottom'" [scrollingEnabled]="true" [hoverStateEnabled]="false"
            (onItemClick)="getParticipantDetail($event)" [searchExpr]="['user.firstName', 'user.lastName', 'user.company']" [searchMode]="'contains'"
            [allowItemDeleting]="false">
            <div *dxTemplate="let item of 'item'">
                <div fxFlex="100" fxLayout="row" class="participant-item" fxLayoutGap="10px">
                    <div fxFlex="40">{{item.user.firstName}} {{item.user.lastName}}</div>
                    <div fxFlex="50">{{item.user.company}}</div>
                    <div fxFlex="5" *ngIf="item.isCheckedIn(selectedDate) === true">
                        <img style="height: 30px; width: 30px; position: relative; top: -3px;" src="assets/images/check-green.svg" />
                    </div>
                    <div fxFlex="5" *ngIf="item.isCheckedIn(selectedDate) === false">
                        <img style="height: 30px; width: 30px; position: relative; top: -3px;" src="assets/images/check-gray.svg" />
                    </div>
                </div>
            </div>
        </dx-list>
    </div>
    <div fxLayout="column">
        <dx-radio-group class="radio" [id]="'filterGroupParticipant'" [items]="checkedParticipantFilter"
            (onValueChanged)="changeFilter($event)" [value]="checkedParticipantFilter[2]" layout="horizontal">
        </dx-radio-group>
    </div>
</div>

<ideenherd-loader *ngIf="showLoader"></ideenherd-loader>
