import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Car } from '@ideenherd';
import { ConvertHelper, DataService } from '@ideenherd/core/data';
import { first, Subscription } from 'rxjs';
import { SINECTROUTES } from '../../app-routing.module';
import { AppService } from '../../app.service';
import { AppConfig } from '../view-models/accreditation-config.view-model';
import { CarViewModel } from '../view-models/car.view-model';
import { EventViewModel } from '../view-models/event.view-model';
import { WorkshopViewModel } from '../view-models/workshop.view-model';
import { EventUserViewModel } from '../view-models/event-user.view-model';
@Component({
  selector: 'ideenherd-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
})
export class EventDetailComponent implements OnInit, OnDestroy {
  public eventDetails: EventViewModel[];
  private subscriptions: Subscription = new Subscription();
  public convertHelper = ConvertHelper;
  public showLoader = false;

  public today: string = new Date().toISOString().split('T')[0];

  public config: AppConfig;
  public multipleButtons: boolean;

  constructor(
    private router: Router,
    public appService: AppService,
    private dataService: DataService
  ) { }

  loadData(): void {
    this.showLoader = true;
    this.appService.clearParticipantData();

    for (const eventDetail of this.eventDetails) {

      const eventId = eventDetail.id;

      this.subscriptions.add(this.dataService.getUsersEventByEventId(eventDetail.id).subscribe(userEvents => {
        userEvents
          .filter(participant => (participant.ue_u_.u_global_authorisation < 10)) // >= 10 are admin users
          .forEach(participant => {
            const eventUser = new EventUserViewModel(participant);
            const eventUserDays = eventUser.days.length > 0 ? eventUser.days : eventDetail.numberOfDays;

            if (!this.appService.participantList[eventId]) this.appService.participantList[eventId] = [];
            if (!this.appService.checkedParticipantList[eventId]) this.appService.checkedParticipantList[eventId] = [];
            if (!this.appService.uncheckedParticipantList[eventId]) this.appService.uncheckedParticipantList[eventId] = [];

            for (const day of eventUserDays) {
              if (!this.appService.participantList[eventId][day]) this.appService.participantList[eventId][day] = [];
              if (!this.appService.checkedParticipantList[eventId][day]) this.appService.checkedParticipantList[eventId][day] = [];
              if (!this.appService.uncheckedParticipantList[eventId][day]) this.appService.uncheckedParticipantList[eventId][day] = [];

              if (eventUser.isCheckedIn(day)) {
                if (!this.appService.checkedParticipantList[eventId][day].find(q => q.user.id === eventUser.user.id)) {
                  this.appService.checkedParticipantList[eventId][day].push(eventUser);
                }
              } else {
                if (!this.appService.uncheckedParticipantList[eventId][day].find(q => q.user.id === eventUser.user.id)) {
                  this.appService.uncheckedParticipantList[eventId][day].push(eventUser);
                }
              }
              if (!this.appService.participantList[eventId][day].find(q => q.user.id === eventUser.user.id)) {
                this.appService.participantList[eventId][day].push(eventUser);
              }
            }
          });
      }, error => {
        console.error(error);
      }, () => {
        this.showLoader = false;
      } ));
    }

  }

  ngOnInit(): void {
    this.showLoader = true;
    this.appService.showBackButton = true;
    this.eventDetails = this.appService.eventDetails;

    localStorage.setItem('startDate', this.eventDetails?.[0]?.startDate);
    localStorage.setItem('endDate', this.eventDetails?.[0]?.endDate);
    localStorage.setItem('numberOfDays', JSON.stringify(this.eventDetails?.[0]?.numberOfDays));
    localStorage.setItem('selectedDate', this.eventDetails?.[0]?.numberOfDays.includes(this.today) ? this.today : this.eventDetails?.[0]?.startDate);
    localStorage.setItem('eventName', this.eventDetails?.[0]?.name);

    this.appService.accreditationConfigLoaded.pipe(first()).subscribe(() => {
      this.config = this.appService.accreditationConfig.config ?? {};
      this.multipleButtons = this.appService.accreditationConfig.multipleButtons;
    });

    if (this.appService.carList.length === 0) {
      this.subscriptions.add(this.dataService.getAllCars<Car[]>().subscribe((cars) => {
        cars.forEach((car) => {
          this.appService.carList.push(new CarViewModel(car));
        });
      }));
    }

    if (this.appService.participantList.length === 0 && this.eventDetails?.length > 0) {
      this.loadData();
    }

    if (this.appService.workshopList.length === 0 && this.eventDetails?.length > 0) {
      for (const eventDetail of this.eventDetails) {
        this.subscriptions.add(this.dataService.getWorkShopList(eventDetail.id).subscribe(WorkShops => {
          WorkShops.forEach((ws) => {
            const workShop = new WorkshopViewModel(ws);
            if (workShop.state === true) {
              this.appService.activeWorkshopList.push(workShop);
            } else {
              this.appService.closedWorkshopList.push(workShop);
            }
            this.appService.workshopList.push(workShop);
          });
        }, error => {
          this.showLoader = false;
        }, () => {
          this.showLoader = false;
        }));
      }
    } else {
      this.showLoader = false;
    }

    this.subscriptions.add(this.appService.backButtonS$.subscribe(() => {
      if (window['deviceReady'] == true) {
        document.addEventListener("backbutton", function (e) {
          this.backToEventVerifier();
        });
      }
      this.backToEventVerifier();
    }));
  }

  private backToEventVerifier = (): void => {
    this.appService.clearData();
    this.router.navigateByUrl('/event-verifier');
    this.appService.showBackButton = false;
  }

  public getScanner(path: AllowedPath, eventId: number): void {
    if (!this.appService.participantList[eventId]) {
      return;
    }

    localStorage.setItem('eventId', eventId.toString());

    if (path === AllowedPath.Car) {
      this.router.navigate([
        SINECTROUTES.EVENT_SCANNER.replace(':path', SINECTROUTES.EVENT_DETAIL)
      ]);
    } else {
      this.router.navigate([
        SINECTROUTES.EVENT_SCANNER.replace(':path', SINECTROUTES.EVENT_DETAIL_PARTICIPANT)
      ]);
    }
  }

  public getCarDetail(): void {
    this.router.navigate([SINECTROUTES.CAR_OVERVIEW]);
  }

  public getWorkshops(): void {
    this.router.navigate([SINECTROUTES.WORKSHOP_OVERVIEW]);
  }

  public openParticipantList(eventId: number, day: string): void {
    if (!this.appService.participantList[eventId][day]) {
      return;
    }

    localStorage.setItem('eventId', eventId.toString());
    localStorage.setItem('eventInfo', this.eventDetails.find(q => q.id === eventId)?.info);
    localStorage.setItem('selectedDate', day);
    this.router.navigate([SINECTROUTES.PARTICIPANT_LIST]);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
export enum AllowedPath {
  Car = 1,
  Participant,
}
