import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {IToast, ToastType, User} from '@ideenherd';
import {ConvertHelper, DataService, PersistenceService} from '@ideenherd/core/data';
import {ICode} from 'libs/core/data/src/lib/data.service';
import {Subject, Subscription, takeUntil} from 'rxjs';
import {SINECTROUTES} from '../../../app-routing.module';
import {AppService} from '../../../app.service';
import {EventViewModel} from "../../view-models/event.view-model";
import {UserViewModel} from '../../view-models/user.view-model';

@Component({
  selector: 'ideenherd-participant',
  templateUrl: './participant.component.html',
  styleUrls: ['./participant.component.scss']
})
export class ParticipantComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();
  private destroy$ = new Subject<void>();
  public showLoader = false;
  public disableCheckButton = false;
  public eventDetail: EventViewModel;

  public eventId = localStorage.getItem('eventId');
  public eventName = localStorage.getItem('eventName');
  public eventInfo = localStorage.getItem('eventInfo');
  public selectedDate = localStorage.getItem('selectedDate');
  public numberOfDays = JSON.parse(localStorage.getItem('numberOfDays'));
  public isAlreadyCheckedIn: boolean = false;
  public isAlreadyCheckedIn2: boolean = sessionStorage.getItem('alreadyCheckedIn') === 'true';

  constructor(
    private router: Router,
    public appService: AppService,
    private dataService: DataService,
    private persistenceService: PersistenceService
  ) { }

  ngOnDestroy(): void {
        this.isAlreadyCheckedIn = undefined;
        this.isAlreadyCheckedIn2 = undefined;
        sessionStorage.removeItem('alreadyCheckedIn');
    }

  ngOnInit(): void {
    this.appService.showBackButton = true;
    this.eventDetail = this.appService.eventDetails?.[0];
    this.isAlreadyCheckedIn = this.appService.eventParticipantDetail?.isCheckedIn(this.selectedDate);

    // create unchecked days if not set
    if (this.appService.eventParticipantDetail?.checkInDays.length === 0) {
      for (const day of this.appService.eventParticipantDetail.days) {
        this.appService.eventParticipantDetail.checkOut(day);
      }
    }

    this.subscriptions.add(this.appService.backButtonS$.subscribe(() => {
      if (window['deviceReady'] == true) {
        document.addEventListener("backbutton", function (e) {
          this.backToParticipantList();
        });
      }
      this.backToParticipantList();
    }));
  }

  private backToParticipantList = (): void => {
    this.router.navigateByUrl(SINECTROUTES.PARTICIPANT_LIST);
  }

  public getParticipantScanner(): void {
    this.router.navigate([
      SINECTROUTES.EVENT_SCANNER.replace(':path', SINECTROUTES.PARTICIPANT_DETAIL)
    ]);
  }

  public setParticipant(check: boolean, day: string): void {
    if (!check) {
      this.isAlreadyCheckedIn = undefined;
      this.isAlreadyCheckedIn2 = undefined;
      sessionStorage.removeItem('alreadyCheckedIn');
    }

    this.showLoader = true;
    this.disableCheckButton = true;
    const body: ICode = { code: this.appService.eventParticipantDetail.user.qrCode };
    this.subscriptions.add(this.dataService.getParticipantByCode<User>(body).pipe(takeUntil(this.destroy$)).subscribe(participantDetail => {
      const scannedParticipant = new UserViewModel(participantDetail);
      if (this.appService.participantList?.[this.eventId]?.[this.selectedDate]?.some(test => test.user.id === scannedParticipant.id)) {
        check ? this.appService.eventParticipantDetail.checkIn(day) : this.appService.eventParticipantDetail.checkOut(day); // Check in participant
        const body = {
          ue_check_in: (check) ? 1 : 0,
          ue_check_in_days: JSON.stringify(this.appService.eventParticipantDetail.checkInDays)
        }
        this.persistenceService.updateUserEventById(this.appService.participantList[this.eventId][this.selectedDate].find(test => test.user.id === scannedParticipant.id).id, body).subscribe(() => {
          this.appService.updateParticipantData(scannedParticipant.id, check, this.eventId, this.selectedDate);
        })
      }
    }, error => {
      this.showLoader = false;
      this.disableCheckButton = false;
      console.error(error);
      this.appService.notrifyError(error);
    }, () => {
      const notification: IToast = { message: "", type: ToastType.SUCCESS, displayTime: 2000 };
      if (check === true) notification.message = "Teilnehmer erfolgreich eingecheckt";
      else notification.message = "Teilnehmer erfolgreich ausgecheckt";
      this.appService.callNotification(notification);
      this.showLoader = false;
      this.disableCheckButton = false;
    }));

    if (!check) {
      this.isAlreadyCheckedIn = false;
    }
  }

  public getWorkshopParticipantOverview(e): void {
    this.appService.workshopDetail = e.itemData;
    this.router.navigate([SINECTROUTES.WORKSHOP_PARTICIPANT_LIST.replace(':path', e.itemData?.id)]);
  }

  protected readonly convertHelper = ConvertHelper;
}

