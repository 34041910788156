import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Accreditation, AccreditationConfig, EventCode, IToast, ToastType } from '@ideenherd';
import { DataService } from '@ideenherd/core/data';
import { first, Subscription } from 'rxjs';
import { ICustomer } from '../../../../../../libs/core/data/src/lib/data.service';
import { SINECTROUTES } from '../../app-routing.module';
import { AppService } from '../../app.service';
import { AccreditationConfigViewModel } from '../view-models/accreditation-config.view-model';
import { EventViewModel } from '../view-models/event.view-model';
import { branch, revision, version } from '../../../version';

@Component({
  selector: 'ideenherd-event-verifier',
  templateUrl: './event-verifier.component.html',
  styleUrls: ['./event-verifier.component.scss'],
})
export class EventVerifierComponent implements OnInit {
  public showSplash = true;
  public eventCode: string;
  private subscriptions: Subscription[] = [];

  public customers: ICustomer[];
  public customer: ICustomer;
  public showCustomers: boolean = !!localStorage.getItem('showCustomers')?.length;

  public version: string = version;
  public revision: string = revision;
  public branch: string = branch;

  constructor(
    private readonly appService: AppService,
    private readonly dataService: DataService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {
  }

  ngOnInit(): void {
    this.dataService.tenantsLoaded.pipe(first()).subscribe(() => {
      const tenant: string = this.route.snapshot.paramMap.get('tenant');
      if (this.dataService.verifyTenant(tenant)) {
        this.dataService.tenant = tenant;
        localStorage.setItem('tenant', this.dataService.tenant);
        if (!this.appService.accreditationConfig) {
          this.dataService.getAccreditationConfigList().pipe(first()).subscribe((accreditationConfigs: AccreditationConfig[]) => {
            this.appService.accreditationConfig = new AccreditationConfigViewModel(accreditationConfigs.pop());
            this.appService.accreditationConfigLoadedSubject.next();
          });
        }
      }
    });

    this.subscriptions.push(this.dataService.getCustomers().subscribe((customers: ICustomer[]) => this.customers = customers));

    this.eventCode = localStorage.getItem('eventCode');
    this.appService.showBackButton = false;

    setTimeout(() => {
      this.showSplash = !this.showSplash;
    }, 1500);

    this.subscriptions.push(this.appService.backButtonS$.subscribe(() => {
      if (window['deviceReady'] == true) {
        document.addEventListener("backbutton", function (e) {
          this.backToOrderList();
        });
      }
      this.backToOrderList();
    }));
  }

  public onCustomerSelect(tenant: string): void {
    localStorage.setItem('tenant', tenant);
    localStorage.removeItem('eventCode');
    window.location.reload();
  }

  private backToOrderList = (): void => {
    this.router.navigateByUrl('/event-verifier');
    this.appService.showBackButton = false;
  }

  getEventDetail() {
    if (this.eventCode) {
      const eventCode: EventCode = {
        login: this.eventCode,
      };
      this.subscriptions.push(this.dataService.getEventByCode<Accreditation>(eventCode).subscribe((eventDetail) => {
            localStorage.setItem('eventCode', this.eventCode);
            const events = [];
            for (const event of eventDetail.a_events_) events.push(new EventViewModel(event));
            this.appService.eventDetails = events;
          }, (error) => {

            const message: IToast = {
              message: "Ungültiger Code",
              type: ToastType.ERROR
            };
            this.appService.callNotification(message);
          }
          , () => {
            this.appService.showBackButton = true;
            this.router.navigate([SINECTROUTES.EVENT_DETAIL]);
          }
        )
      );
    } else {
      const message: IToast = {
        message: "Leerer Code",
        type: ToastType.ERROR
      };
      //this.appService.callNotification(message);
    }
  }
}

























