import { CheckInDays, EventUser } from '@ideenherd';
import { HotelReservationViewModel } from './hotel-reservation.view-model';
import { UserViewModel } from './user.view-model';
import { WorkshopViewModel } from './workshop.view-model';

export class EventUserViewModel {
  protected eventUserData: EventUser;

  get id(): number {
    return this.eventUserData.ue_id;
  }

  get eventId(): number {
    return this.eventUserData.ue_e_id;
  }

  get userEventId(): number {
    return this.eventUserData.ue_e_id;
  }

  get user(): UserViewModel {
    return new UserViewModel(this.eventUserData.ue_u_);
  }

  get userId(): number {
    return this.eventUserData.ue_u_.u_id;
  }

  get days(): string[] {
    return this.eventUserData.ue_u_.db_users_events_days?.map((q: any) => q.ued_day);
  }

  get hotelReservation(): HotelReservationViewModel {
    return new HotelReservationViewModel(this.eventUserData.db_hotel_reservation);
  }

  get workshops(): WorkshopViewModel[] {
    return this.eventUserData.ue_u_.db_users_workshops?.map(q => new WorkshopViewModel(q.wu_w_));
  }

  get checkInDays(): CheckInDays[] {
    return JSON.parse(this.eventUserData.ue_check_in_days || null)?.sort((a: CheckInDays, b: CheckInDays) => a.day.localeCompare(b.day)) || [];
  }

  set checkInDays(data: CheckInDays[]){
    this.eventUserData.ue_check_in_days = JSON.stringify(data);
  }

  checkIn(day: string): void {
    const data: CheckInDays[] = this.checkInDays.filter((cid: CheckInDays) => cid.day !== day);
    data.push({day, checkedIn: true});
    this.checkInDays = data;
  }

  checkOut(day: string): void {
    const data: CheckInDays[] = this.checkInDays.filter((cid: CheckInDays) => cid.day !== day);
    data.push({day, checkedIn: false});
    this.checkInDays = data;
  }

  isCheckedIn(day: string): boolean {
    return this.checkInDays.some((cid: CheckInDays) => cid.day === day && cid.checkedIn);
  }

  get privacy(): boolean {
    return this.eventUserData.ue_privacy === 1;
  }

  get compliancePhoto(): boolean {
    return this.eventUserData.ue_compliance_photo === 1;
  }

  constructor(eventUser: EventUser) {
    this.eventUserData = eventUser;
  }
}
