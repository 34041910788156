<div [style.filter]="appService.scannerProcessLoader == true ? 'blur(0.2rem)' : 'none'">
  <div fxLayout="column">
    <div fxLayout="row" class="information-header" fxLayoutAlign="space-between center">
      <div fxLayout="column">{{eventName}} <ng-container *ngIf="appService.eventDetails?.length > 1 && appService.accreditationConfig.multipleButtons">({{eventInfo}})</ng-container></div>
      <div fxLayout="column">{{selectedDate | date:'dd.MM.YYYY'}}</div>
    </div>
  </div>
  <dx-scroll-view>
    <div class="background-body" style="padding: 8px" fxFlex="100" fxLayout="column" fxLayoutAlign="center center"
      fxLayoutGap="8PX" fxFill>
      <div fxLayout="row">
        <div fxFlex="100">
          <h1 style="color: black 'Source Sans Pro', sans-serif">{{QrCodeTitle}}</h1>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" *ngIf="numberOfDays.length > 1">
        <ng-container *ngFor="let day of numberOfDays">
          <span class="day" [class.selected]="selectedDate === day" (click)="setSelectedDate(day)">{{day|date:'dd.MM.YYYY'}}</span>
        </ng-container>
      </div>
      <br>
      <div class="qr-code-style" fxLayout="row" fxLayoutAlign="center center">
        <ideenherd-qr-scanner #scanner (onScanSuccess)="scanSuccess($event)"></ideenherd-qr-scanner>
      </div>
    </div>
  </dx-scroll-view>
</div>
<dx-toast #toast [type]="'error'" [message]="'Ungültiger Code'" [displayTime]="3000" [position]="toastPosition"
  [width]="'auto'">
</dx-toast>

<ideenherd-loader *ngIf="appService.scannerProcessLoader"></ideenherd-loader>
