import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreConfigService } from '@ideenherd/core/configuration';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { ConvertHelper } from './helpers';

@Injectable({
    providedIn: 'root'
})
export class PersistenceService {
    private startAppointmentUri: string;
    private appointmentByIdUri: string;
    private userWorkShopdUri: string;
    private userEventByIdUri: string;
    private usersWorkshopUri: string;
    private usersEventsAccreditationUri: string;
    private UsersEventsAccreditationById: string;
    private UsersEventsAccreditationByUserId: string;

    constructor(private http: HttpClient, private configService: CoreConfigService, private dataService: DataService) {
        this.startAppointmentUri = this.configService.configuration.WebApi.ServiceURLs.StartAppointment;
        this.appointmentByIdUri = this.configService.configuration.WebApi.ServiceURLs.AppointmentById;
        this.userWorkShopdUri = this.configService.configuration.WebApi.ServiceURLs.UsersWorkshopById;
        this.userEventByIdUri = this.configService.configuration.WebApi.ServiceURLs.UsersEventById;
        this.usersWorkshopUri = this.configService.configuration.WebApi.ServiceURLs.UsersWorkshop;
        this.usersEventsAccreditationUri = this.configService.configuration.WebApi.ServiceURLs.UsersEventsAccreditation;
        this.UsersEventsAccreditationById = this.configService.configuration.WebApi.ServiceURLs.UsersEventsAccreditationById;
        this.UsersEventsAccreditationByUserId = this.configService.configuration.WebApi.ServiceURLs.UsersEventsAccreditationByUserId;
    }

    public addAppointment(query): Observable<any> {
        return this.dataService.executeWebApiPostMethod((ConvertHelper.resolveStringPlaceholders(this.startAppointmentUri, this.dataService.tenant)), query);
    }

    public updateAppontmentById(id, query): Observable<any> {
        return this.dataService.executeWebApiPatchMethod((ConvertHelper.resolveStringPlaceholders(this.appointmentByIdUri, this.dataService.tenant, id)), query);
    }

    public updateUserWorkShopById(id, query): Observable<any> {
        return this.dataService.executeWebApiPatchMethod((ConvertHelper.resolveStringPlaceholders(this.userWorkShopdUri, this.dataService.tenant, id)), query);
    }
    public updateUserEventById(id, query): Observable<any> {
        return this.dataService.executeWebApiPatchMethod((ConvertHelper.resolveStringPlaceholders(this.userEventByIdUri, this.dataService.tenant, id)), query);
    }
    public insertUserWorkShop(query): Observable<any> {
        return this.dataService.executeWebApiPostMethod((ConvertHelper.resolveStringPlaceholders(this.usersWorkshopUri, this.dataService.tenant)), query);
    }
    public deleteUserWorkShop(id): Observable<any> {
        return this.dataService.executeWebApiDeleteMethod((ConvertHelper.resolveStringPlaceholders(this.userWorkShopdUri, this.dataService.tenant, id)));
    }

    public getUsersEventsAccreditation(id, query): Observable<any> {
      return this.dataService.executeWebApiGetMethod((ConvertHelper.resolveStringPlaceholders(this.UsersEventsAccreditationByUserId, this.dataService.tenant, id)));
    }
    public insertUsersEventsAccreditation(query): Observable<any> {
        return this.dataService.executeWebApiPostMethod((ConvertHelper.resolveStringPlaceholders(this.usersEventsAccreditationUri, this.dataService.tenant)), query);
    }
    public updateUsersEventsAccreditation(id, query): Observable<any> {
      return this.dataService.executeWebApiPatchMethod((ConvertHelper.resolveStringPlaceholders(this.UsersEventsAccreditationById, this.dataService.tenant, id)), query);
    }
}

export interface IAppointment {
    a_user_id: number;
    users?: string[];
    a_additional_users?: string;
    a_car_id: number;
    a_event_id: number;
    a_check_in_time: Date;
    a_check_out_time?: Date;
    // FIXME: not null in DB
    a_start_date: string;
    a_start_time: string;
    a_end_date: string;
    a_end_time: string;
    a_km_start: number;
    a_km_end: number;
    a_fuel_start: number;
    a_fuel_end: number;
    a_price1: number;
    a_price2: number;
    a_price3: number;
    a_price4: number;
    a_werbung: number;
    a_werbung_phone: number;
    a_werbung_email: number;
    a_signature_customer: string;
    a_signature_customer2: string;
    a_signature_seller: string;
    a_signature_date: string;
    a_price_mandatory: number;
    a_change_contract: number;
    //////////////////////////
}




