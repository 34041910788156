<div fxLayout="column" [style.filter]="showLoader == true || !appService.eventParticipantDetail ? 'blur(0.2rem)' : 'none'" fxFill>
    <div fxLayout="column">
        <div fxLayout="row" class="information-header" fxLayoutAlign="space-between center">
          <div fxLayout="column">{{eventName}} <ng-container *ngIf="appService.eventDetails?.length > 1">({{eventInfo}})</ng-container></div>
          <div fxLayout="column">{{selectedDate | date:'dd.MM.YYYY'}}</div>
        </div>
    </div>
    <div class="default-layout-style double-toolbar">
        <dx-scroll-view>
            <div class="participant-details-slim" style="background-color: red; color: #fff;" fxLayout="row" *ngIf="isAlreadyCheckedIn2 || (isAlreadyCheckedIn2 === undefined && isAlreadyCheckedIn)">
              <div fxFlex="100" fxLayout="column" fxLayoutAlign="center center" style="font-weight: bold;">
                <div fxLayout="row" style="margin-bottom: 5px">
                  <div fxLayout="column">
                    <b>Teilnehmer ist bereits eingecheckt!</b>
                  </div>
                </div>
              </div>
            </div>
            <div class="participant-details" fxLayout="row" *ngIf="appService.eventParticipantDetail">
                <div fxFlex="70" fxLayout="column" fxLayoutAlign="start start" style="font-weight: bold;">
                    <div fxLayout="row" style="margin-bottom: 5px">
                        <div fxLayout="column">
                            <small>Name</small>
                            <b>{{appService.eventParticipantDetail.user.firstName}} {{appService.eventParticipantDetail.user.lastName}}</b>
                        </div>
                    </div>
                    <div fxLayout="row" style="margin-bottom: 5px" *ngIf="appService.eventParticipantDetail.user.company">
                        <div fxLayout="column">
                            <small>Firma</small>
                            <b>{{appService.eventParticipantDetail.user.company}}</b>
                        </div>
                    </div>
                  <div fxLayout="row" style="margin-bottom: 5px" *ngIf="appService.eventParticipantDetail.user.division">
                      <div fxLayout="column">
                          <small>Abteilung</small>
                          <b>{{appService.eventParticipantDetail.user.division}}</b>
                      </div>
                  </div>
                  <div fxLayout="row" style="margin-bottom: 5px" *ngIf="appService.eventParticipantDetail.user.task">
                      <div fxLayout="column">
                          <small>Aufgabe</small>
                          <b>{{appService.eventParticipantDetail.user.task}}</b>
                      </div>
                  </div>
                  <div fxLayout="row" style="margin-bottom: 5px">
                    <div fxLayout="column">
                        <small>Zustimmung Foto- und Filmerlaubnis</small>
                        <b>{{appService.eventParticipantDetail.compliancePhoto ? 'Ja' : 'Nein'}}</b>
                    </div>
                  </div>
                  <div fxLayout="row" style="margin-bottom: 5px">
                    <div fxLayout="column">
                        <small>Zustimmung Datenschutzerklärung</small>
                        <b>{{appService.eventParticipantDetail.privacy ? 'Ja' : 'Nein'}}</b>
                    </div>
                  </div>
                  <div fxLayout="row" style="margin-bottom: 5px" *ngIf="appService.eventParticipantDetail.hotelReservation?.id">
                    <div fxLayout="column">
                        <small>Hotelbuchung</small>
                        <b>{{appService.eventParticipantDetail.hotelReservation?.hotel?.name}}<br>{{appService.eventParticipantDetail.hotelReservation?.dateStart|date:'dd.MM.YY'}} - {{appService.eventParticipantDetail.hotelReservation?.dateEnd|date:'dd.MM.YY'}}</b>
                    </div>
                  </div>
                </div>
                <div fxFlex="30" fxLayout="column" fxLayoutAlign="center end">
                    <div fxLayout="row" class="enter-icon-style"
                        *ngIf="appService.eventParticipantDetail.isCheckedIn(selectedDate)">
                        <img style="height: 60px; width: 60px;" src="assets/images/verify-green.svg" />
                        <dx-button class="check-participant" style="margin-left: 10px;"
                            icon="assets/images/user-minus.svg " [disabled]="disableCheckButton"
                            (onClick)="setParticipant(false, selectedDate)">
                        </dx-button>
                    </div>
                    <div fxLayout="row" class="enter-icon-style"
                        *ngIf="!appService.eventParticipantDetail.isCheckedIn(selectedDate)">
                        <img style="height: 60px; width: 60px;" src="assets/images/verify-red.svg" />
                        <dx-button class="check-participant" style="margin-left: 10px;"
                            icon="assets/images/user-plus.svg " [disabled]="disableCheckButton"
                            (onClick)="setParticipant(true, selectedDate)">
                        </dx-button>
                    </div>
                </div>
            </div>
            <div class="participant-details-slim" fxLayout="row" *ngFor="let cid of appService.eventParticipantDetail?.checkInDays">
                <div fxFlex="70" fxLayout="column" fxLayoutAlign="center start" style="font-weight: bold;">
                  <div fxLayout="row" style="margin-bottom: 5px">
                    <div fxLayout="column">
                      <b>{{cid.day|date:'dd.MM.'}}</b>
                    </div>
                  </div>
                </div>
                <div fxFlex="30" fxLayout="column" fxLayoutAlign="center end">
                  <div fxLayout="row" class="enter-icon-style"
                       *ngIf="appService.eventParticipantDetail.isCheckedIn(cid.day)">
                    <img style="height: 30px; width: 30px;" src="assets/images/verify-green.svg" />
                  </div>
                  <div fxLayout="row" class="enter-icon-style"
                       *ngIf="!appService.eventParticipantDetail.isCheckedIn(cid.day)">
                    <img style="height: 30px; width: 30px;" src="assets/images/verify-red.svg" />
                  </div>
                </div>
              </div>
            <!--
            <div fxLayout="row">
                <dx-button class="button-scan-participant" [height]="'85px'" [width]="'100%'"
                    text="Weiteren Teilnehmer einchecken" type="default" icon="assets/images/qrcode.svg "
                    (onClick)="getParicipantScanner()">
                </dx-button>
            </div>
            -->
            <div  [class.hidden]="appService.eventParticipantDetail?.workshops.length === 0" *ngIf="appService.eventParticipantDetail">
              <dx-list class="workshop-list" [searchEnabled]="false" [dataSource]="appService.eventParticipantDetail.workshops"
                       [pageLoadMode]="'scrollBottom'" [scrollingEnabled]="true" [hoverStateEnabled]="false"
                       (onItemClick)="getWorkshopParticipantOverview($event)"
                       [allowItemDeleting]="false" style="margin-top: 20px;">
                <div *dxTemplate="let workshop of 'item'">
                  <div fxLayoutAlign="space-between center"
                       [ngClass]="{'workshop-list-item-future': workshop.checked === true,'workshop-list-item-taken': workshop.checked === false}">
                    <div fxFlex="100" fxLayout="column" fxLayoutAlign="space-around stretch" class="workshop-item">
                      <div fxLayout="row">
                        <div fxLayout="column" fxFlex="35"> Workshop: </div>
                        <div fxLayout="column" fxFlex="65"> {{workshop.name}}</div>
                      </div>
                      <div fxLayout="row">
                        <div fxLayout="column" fxFlex="35">Datum / Uhrzeit: </div>
                        <div fxLayout="column" fxFlex="65">
                          {{convertHelper.toDate(workshop.startDate?.toString()) }} /
                          {{convertHelper.toTime(workshop.startDate?.toString()) }} -
                          {{convertHelper.toTime(workshop.endDate?.toString()) }} Uhr
                        </div>
                      </div>
                    </div>
                    <!--
                    <div fxLayout="column" fxLayoutAlign="space-around center">
                      <div *ngIf="workshop?.state === true" fxLayout="row">
                        <img style="height: 30px;width: 30px" src="assets/images/calendar-green.svg" />
                      </div>
                      <div *ngIf="workshop?.state === false" fxLayout="row">
                        <img style="height: 30px;width: 30px" src="assets/images/calendar-red.svg" />
                      </div>
                    </div>
                    -->
                  </div>
                </div>
              </dx-list>
            </div>
        </dx-scroll-view>
    </div>
</div>

<ideenherd-loader *ngIf="showLoader || !appService.eventParticipantDetail"></ideenherd-loader>
