import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccreditationConfig, EventUser, IUsersEventsAccreditation, UserWorkshop, WorkShop } from '@ideenherd';
import { CoreConfigService } from '@ideenherd/core/configuration';
import { TokenModel } from 'libs/authentication/src/lib/models/login-info.model';
import { TokenService } from 'libs/authentication/src/lib/services/token.service';
import { first, Observable, ReplaySubject } from 'rxjs';
import { ConvertHelper } from './helpers';

@Injectable({ providedIn: 'root' })
export class DataService {
  private _tenant: string;
  private _tenantList: string[] = [];
  private _tenantsLoadedSubject: ReplaySubject<void> = new ReplaySubject<void>(1);

  private readonly customersUri: string;
  private readonly tenantListUri: string;
  private readonly eventDetailUri: string;
  private readonly carDetailUri: string;
  private readonly participantDriverUri: string;
  private readonly carsListUri: string;
  private readonly accreditationListUri: string;
  private readonly accreditationConfigListUri: string;
  private readonly workshopListUri: string;
  private readonly workShopByIdUri: string;
  private readonly usersWorkShopByIdUri: string;
  private readonly usersWorkShopByWorkshopId: string;
  private readonly participantListUri: string;
  private readonly participantListByEventUri: string;
  private readonly usersEventByEventIdUri: string;
  private readonly usersWorkShopByUserIdUri: string;
  private readonly usersEventsAccreditationUri: string;
  private readonly usersEventsAccreditationsByEventUri: string;
  private readonly usersEventsAccreditationsByDayUri: string;

  constructor(private http: HttpClient, private configService: CoreConfigService, private tokenService: TokenService) {
    this.customersUri = this.configService.configuration.WebApi.ServiceURLs.Customers;
    this.tenantListUri = this.configService.configuration.WebApi.ServiceURLs.TenantList;
    this.eventDetailUri = this.configService.configuration.WebApi.ServiceURLs.EventDetail;
    this.carDetailUri = this.configService.configuration.WebApi.ServiceURLs.CarDetail;
    this.participantDriverUri = this.configService.configuration.WebApi.ServiceURLs.ParticipantDriverDetail;
    this.carsListUri = this.configService.configuration.WebApi.ServiceURLs.CarsList;
    this.accreditationListUri = this.configService.configuration.WebApi.ServiceURLs.AccreditationList;
    this.accreditationConfigListUri = this.configService.configuration.WebApi.ServiceURLs.AccreditationConfigList;
    this.usersWorkShopByWorkshopId = this.configService.configuration.WebApi.ServiceURLs.UsersWorkshopByWorkshopId;
    this.workshopListUri = this.configService.configuration.WebApi.ServiceURLs.WorkshopList;
    this.workShopByIdUri = this.configService.configuration.WebApi.ServiceURLs.WorkshopsById;
    this.usersWorkShopByIdUri = this.configService.configuration.WebApi.ServiceURLs.UsersWorkshopById;
    this.participantListUri = this.configService.configuration.WebApi.ServiceURLs.ParticipantList;
    this.participantListByEventUri = this.configService.configuration.WebApi.ServiceURLs.ParticipantEventList;
    this.usersEventByEventIdUri = this.configService.configuration.WebApi.ServiceURLs.UsersEventByEventId;
    this.usersWorkShopByUserIdUri = this.configService.configuration.WebApi.ServiceURLs.UsersWorkshopByUserId;
    this.usersEventsAccreditationUri = this.configService.configuration.WebApi.ServiceURLs.UsersEventsAccreditation;
    this.usersEventsAccreditationsByEventUri = this.configService.configuration.WebApi.ServiceURLs.UsersEventsAccreditationByEventId;
    this.usersEventsAccreditationsByDayUri = this.configService.configuration.WebApi.ServiceURLs.UsersEventsAccreditationByEventDay;

    this.prepareToken().pipe(first()).subscribe(token => {
      tokenService.saveToken(token);

      this.getTenants().pipe(first()).subscribe(tenantList => {
        this._tenantList = tenantList;
        this._tenantsLoadedSubject.next();
      });
    });
  }

  get tenant() {
    return this._tenant;
  }

  set tenant(tenant: string) {
    this._tenant = tenant;
  }

  public verifyTenant(tenant: string): boolean {
    return this._tenantList.includes(tenant);
  }

  get tenantsLoaded(): Observable<void> {
    return this._tenantsLoadedSubject.asObservable();
  }

  public getCustomers(): Observable<ICustomer[]> {
    return this.executeWebApiGetMethod(this.customersUri);
  }

  public getTenants(): Observable<string[]> {
    return this.executeWebApiGetMethod(this.tenantListUri);
  }

  public getUsersEventsAccreditation<T>(): Observable<T> {
    return this.executeWebApiGetMethod(ConvertHelper.resolveStringPlaceholders(this.usersEventsAccreditationUri, this.tenant));
  }

  public getEventByCode<T>(query: ILogin): Observable<T> {
    return this.executeWebApiGetMethod(ConvertHelper.resolveStringPlaceholders(this.eventDetailUri, this.tenant, query.login));
  }

  public getCarDetailByCode<T>(query: ICode): Observable<T> {
    return this.executeWebApiGetMethod(ConvertHelper.resolveStringPlaceholders(this.carDetailUri, this.tenant, query.code));
  }

  public getParticipantByCode<T>(query: ICode): Observable<T> {
    return this.executeWebApiGetMethod(ConvertHelper.resolveStringPlaceholders(this.participantDriverUri, this.tenant, query.code));
  }

  public getParticipantList<T>(): Observable<T> {
    return this.executeWebApiGetMethod(ConvertHelper.resolveStringPlaceholders(this.participantListUri, this.tenant));
  }

  public getAllCars<Car>(): Observable<Car> {
    return this.executeWebApiGetMethod(ConvertHelper.resolveStringPlaceholders(this.carsListUri, this.tenant));
  }

  public getAccreditationList<T>(): Observable<T> {
    return this.executeWebApiGetMethod(ConvertHelper.resolveStringPlaceholders(this.accreditationListUri, this.tenant));
  }

  public getUsersEventByEventId(id: number): Observable<EventUser[]> {
    const params = {
      select: JSON.stringify({
        ue_id: true,
        ue_e_id: true,
        ue_check_in: true,
        ue_check_in_days: true,
        ue_privacy: true,
        ue_compliance_photo: true,
        ue_u_: {
          u_id: true,
          u_event_id: true,
          u_company: true,
          u_division: true,
          u_task: true,
          u_surname: true,
          u_forename: true,
          u_street: true,
          u_zip: true,
          u_city: true,
          u_phone: true,
          u_mobile: true,
          u_email: true,
          u_birthday: true,
          u_qr_code: true,
          u_avatar: true,
          u_drivers_license: true,
          u_external_id: true,
          u_global_authorisation: true,
          db_users_events_accreditation: {
            uea_id: true,
            uea_day: true,
            uea_datetime: true,
            uea_action: true
          },
          db_users_events_days: {
            ued_day: true
          },
          db_users_workshops: {
            wu_action: true,
            wu_w_: {
              w_id: true,
              w_s_date: true,
              w_e_date: true,
              w_name: true
            }
          }
        },
        db_hotel_reservation: {
          hr_date_start: true,
          hr_date_end: true,
          hr_h_: {
            h_name: true
          }
        }
      })
    };

    return this.executeWebApiGetMethod(ConvertHelper.resolveStringPlaceholders(this.usersEventByEventIdUri, this.tenant, id), params);
  }

  public getUsersEventsAccreditationsByEventId(id: number): Observable<IUsersEventsAccreditation[]> {
    return this.executeWebApiGetMethod(ConvertHelper.resolveStringPlaceholders(this.usersEventsAccreditationsByEventUri, this.tenant, id));
  }

  public getUsersEventsAccreditationsByEventDay(day: string): Observable<IUsersEventsAccreditation[]> {
    return this.executeWebApiGetMethod(ConvertHelper.resolveStringPlaceholders(this.usersEventsAccreditationsByDayUri, this.tenant, day));
  }

  public getAccreditationConfigList(): Observable<AccreditationConfig[]> {
    return this.executeWebApiGetMethod(ConvertHelper.resolveStringPlaceholders(this.accreditationConfigListUri, this.tenant));
  }

  public getWorkShopList(id): Observable<WorkShop[]> {
    return this.executeWebApiGetMethod(ConvertHelper.resolveStringPlaceholders(this.workshopListUri, this.tenant, id));
  }

  public getWorkShopById<WorkShop>(id: number): Observable<WorkShop> {
    return this.executeWebApiGetMethod(ConvertHelper.resolveStringPlaceholders(this.workShopByIdUri, this.tenant, id));
  }

  public getUsersWorkshopByUserId(id: number): Observable<UserWorkshop[]> {
    return this.executeWebApiGetMethod(ConvertHelper.resolveStringPlaceholders(this.usersWorkShopByUserIdUri, this.tenant, id));
  }

  public getUsersWorkshopByWorkshopId(id: number): Observable<UserWorkshop[]> {
    return this.executeWebApiGetMethod(ConvertHelper.resolveStringPlaceholders(this.usersWorkShopByWorkshopId, this.tenant, id));
  }

  public getUsersWorkShopById<WorkShop>(id): Observable<WorkShop> {
    return this.executeWebApiGetMethod(ConvertHelper.resolveStringPlaceholders(this.usersWorkShopByIdUri, this.tenant, id));
  }

  public getEventParticipantById<WorkShop>(id): Observable<WorkShop> {
    return this.executeWebApiGetMethod(ConvertHelper.resolveStringPlaceholders(this.participantListByEventUri, this.tenant, id));
  }

  public executeWebApiGetMethod<T>(url: string, params?: any): Observable<T> {
    return this.http.get<T>(url, { params: params });
  }

  public executeWebApiPostMethod(url: string, body: any): Observable<any> {
    return this.http.post(url, body);
  }

  public executeWebApiPutMethod(url, body): Observable<any> {
    return this.http.put(url, body);
  }

  public executeWebApiPatchMethod(url, body): Observable<any> {
    return this.http.patch(url, body);
  }

  public executeWebApiDeleteMethod(url): Observable<any> {
    return this.http.delete(url);
  }

  public prepareToken(): Observable<TokenModel> {
    const headers = new HttpHeaders().set('Authorization', this.configService.configuration.OAuth2.ClientId);
    return this.http.get<TokenModel>(this.configService.configuration.WebApi.ServiceURLs.GenerateToken, { headers: headers });
  }
}

export interface ICustomer {
  c_name: string;
  c_tenant: string;
}

export interface ILogin {
  login: string
}

export interface ICode {
  code: string
}

