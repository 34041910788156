<div
  *ngIf="!showSplash; else elseBlock"
  class="orderDetailStyle"
  style="padding: 8px"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="20px"
  fxFill
>

  <div fxLayout="row">
    <span class="event-message">Veranstaltungscode</span>
  </div>

  <div fxLayout="row">
    <dx-text-box
      [(value)]="eventCode"
      id="txtCode"
      class="event-code-button"
      placeholder="Code"
      width="250px"
      height="60px"
    ></dx-text-box>
  </div>

  <div fxLayout="row">
    <dx-button
      (onClick)="getEventDetail()"
      class="login-button"
      stylingMode="contained"
      type="default"
      text="LOGIN"
      icon="assets/images/verify-white.svg"
      width="250px"
      height="60px"
    ></dx-button>
  </div>

  <div *ngIf="showCustomers" fxLayout="row">
    <select [(ngModel)]="customer" (change)="onCustomerSelect(customer.c_tenant)">
      <option disabled selected value="undefined">Mandant wechseln</option>
      <option *ngFor="let customer of customers" [ngValue]="customer">{{customer.c_name}}</option>
    </select>
  </div>

  <small style="color:#ccc; padding-top:50px; font-family:monospace;">
    Version {{version}}-{{branch}}+sha.{{revision}}
  </small>

</div>

<ng-template #elseBlock>
  <ideenherd-splash-screen></ideenherd-splash-screen>
</ng-template>
