import { Hotel, HotelReservation } from '@ideenherd';

export class HotelViewModel {
  protected hotelData: Hotel;

  get id(): number {
    return this.hotelData?.h_id;
  }

  get name(): string {
    return this.hotelData?.h_name;
  }

  get description(): string {
    return this.hotelData?.h_description;
  }

  get street(): string {
    return this.hotelData?.h_street;
  }

  get zip(): string {
    return this.hotelData?.h_zip;
  }

  get city(): string {
    return this.hotelData?.h_city;
  }

  get country(): string {
    return this.hotelData?.h_country;
  }

  get phone(): string {
    return this.hotelData?.h_phone;
  }

  get fax(): string {
    return this.hotelData?.h_fax;
  }

  get web(): string {
    return this.hotelData?.h_web;
  }

  get email(): string {
    return this.hotelData?.h_email;
  }

  get both(): boolean {
    return this.hotelData?.h_both === 1;
  }

  get booking(): Date {
    return this.hotelData?.h_booking;
  }

  get internal(): boolean {
    return this.hotelData?.h_internal === 1;
  }

  get image(): string {
    return this.hotelData?.h_image;
  }

  constructor(hotel: Hotel) {
    this.hotelData = hotel;
  }
}
