<div [style._filter]="showLoader == true ? 'blur(0.2rem)' : 'none'" >
  <div fxLayout="column">
    <div fxLayout="row" class="information-header" fxLayoutAlign="space-between center">
      <div fxLayout="column">{{eventDetails?.[0]?.name}}</div>
      <div fxLayout="row">
        <span>{{ eventDetails?.[0]?.startDate | date:'dd.MM.YYYY' }}</span><span *ngIf="eventDetails?.[0]?.startDate !== eventDetails?.[0]?.endDate">&nbsp;-&nbsp;{{ eventDetails?.[0]?.endDate | date:'dd.MM.YYYY' }}</span>
      </div>
    </div>
  </div>
  <div class="default-layout-style double-toolbar">
    <dx-scroll-view class="scroll-view" *ngIf="config" style="margin-top: 0;">
      <div fxLayout="column">
        <div fxLayout="row" class="button-style" *ngIf="config.testDrive">
          <dx-button style="text-align: center ;" class="button-car" [height]="'60px'" [width]="'100%'" type="default"
                     text="{{ appService.carList.length }} Fahrzeuge" (onClick)="getCarDetail()">
          </dx-button>
        </div>
        <div fxLayout="row" class="button-style" *ngIf="config.testDrive">
          <dx-button class="button-scan" [height]="'90px'" [width]="'100%'" text="Neue Fahrt starten" type="default"
                     icon="assets/images/qrcode.svg " (onClick)="getScanner(1, eventDetails?.[0]?.id)">
          </dx-button>
        </div>
        <div *ngFor="let eventDetail of eventDetails">
          <div fxLayout="row" *ngIf="eventDetails?.length > 1">
            <div style="font-weight: bold; margin-bottom: 10px;">{{eventDetail?.info}}</div>
          </div>
          <ng-container *ngFor="let day of eventDetail.numberOfDays">
            <div fxLayout="row" class="button-style" *ngIf="config.participants">
              <dx-button class="button-participant" style="overflow: hidden;" [class.today]="day === today" [height]="'60px'" [width]="'100%'"
                         type="default" (onClick)="openParticipantList(eventDetail.id, day)" template="button-template">
                <div *dxTemplate="let data of 'button-template'" style="display: flex; align-items: center; justify-content: space-between">
                <span>
                  <span *ngIf="eventDetail.numberOfDays.length > 1">{{day | date:'dd.MM.'}}</span>
                </span>
                  <div style="position: relative; top: -85px;" *ngIf="!appService.participantList?.[eventDetail.id]?.[day]">
                    <ideenherd-loader></ideenherd-loader>
                  </div>
                  <span *ngIf="appService.participantList?.[eventDetail.id]?.[day]">Teilnehmer {{ appService.checkedParticipantList?.[eventDetail.id]?.[day]?.length ?? 0 }} / {{ appService.participantList?.[eventDetail.id]?.[day]?.length ?? 0 }}</span>
                </div>
              </dx-button>
            </div>
          </ng-container>
          <div fxLayout="row" class="button-style" *ngIf="config.participants && multipleButtons">
            <dx-button class="button-scan" [height]="'90px'" [width]="'100%'"
                       text="{{eventDetail.numberOfDays.length > 1 ? 'Teilnehmer einchecken (heute)' : 'Teilnehmer einchecken'}}"
                       type="default" icon="assets/images/qrcode.svg " (onClick)="getScanner(2, eventDetail.id)">
            </dx-button>
          </div>
        </div>
        <div fxLayout="row" class="button-style" *ngIf="config.participants && !multipleButtons">
          <dx-button class="button-scan" [height]="'90px'" [width]="'100%'"
                     text="{{eventDetails?.[0]?.numberOfDays.length > 1 ? 'Teilnehmer einchecken (heute)' : 'Teilnehmer einchecken'}}"
                     type="default" icon="assets/images/qrcode.svg " (onClick)="getScanner(2, eventDetails?.[0]?.id)">
          </dx-button>
        </div>
        <div fxLayout="row" class="button-style workshop" *ngIf="config.workshops">
          <dx-button class="button-workshop" [height]="'85px'" [width]="'100%'" text="Workshops" type="default"
                     (onClick)="getWorkshops()">
          </dx-button>
        </div>
        <div fxLayout="row" class="button-style reload">
          <dx-button class="button-reload" [height]="'60px'" [width]="'100%'" text="Daten neu laden" type="default"
                     (onClick)="loadData()">
          </dx-button>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</div>

<!--
<ideenherd-loader *ngIf="showLoader"></ideenherd-loader>
-->
