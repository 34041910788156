import { HotelReservation } from '@ideenherd';
import { HotelViewModel } from './hotel.view-model';

export class HotelReservationViewModel {
  protected hotelReservationData: HotelReservation;

  get id(): number {
    return this.hotelReservationData?.hr_id;
  }

  get userEventId(): number {
    return this.hotelReservationData?.hr_ue_id;
  }

  get hotelId(): number {
    return this.hotelReservationData?.hr_h_id;
  }

  get hotel(): HotelViewModel {
    return new HotelViewModel(this.hotelReservationData?.hr_h_);
  }

  get hotelQuotaId(): number {
    return this.hotelReservationData?.hr_hq_id;
  }

  get dateStart(): Date {
    return this.hotelReservationData?.hr_date_start;
  }

  get dateEnd(): Date {
    return this.hotelReservationData?.hr_date_end;
  }

  get quota(): number {
    return this.hotelReservationData?.hr_quota;
  }

  get type(): number {
    return this.hotelReservationData?.hr_type;
  }

  get checkboxPayment(): boolean {
    return this.hotelReservationData?.hr_checkbox_payment === 1;
  }

  get comment(): string {
    return this.hotelReservationData?.hr_comment;
  }

  get smoker(): boolean {
    return this.hotelReservationData?.hr_smoker === 1;
  }

  constructor(hotelReservation: HotelReservation) {
    this.hotelReservationData = hotelReservation[0];
  }
}
