import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import QrScanner from 'qr-scanner';
import Camera = QrScanner.Camera;

@Component({
  selector: 'ideenherd-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss'],
})
export class QrScannerComponent implements AfterViewInit, OnDestroy {

  @Input() public enableScanner: boolean = true;
  @Output() public onScanSuccess: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('video') videoElement: ElementRef<HTMLVideoElement>;

  qrScanner: QrScanner;
  cameras: QrScanner.Camera[];
  selectedCamera: QrScanner.Camera;

  constructor(private ngZone: NgZone) {
  }

  async ngAfterViewInit() {
    this.qrScanner = new QrScanner(
      this.videoElement.nativeElement,
      (result: QrScanner.ScanResult) => this.ngZone.run(() => this.scanSuccess(result.data)),
      {
        preferredCamera: 'environment',
        returnDetailedScanResult: true,
        highlightScanRegion: true,
        highlightCodeOutline: true,
      }
    );
    await this.qrScanner.start();
    this.cameras = await QrScanner.listCameras();
    this.selectedCamera = this.cameras.find((camera: Camera) => camera.id === localStorage.getItem('cameraId')) ?? this.cameras[0];
    await this.qrScanner.setCamera(this.selectedCamera.id);
  }

  public onCameraSelect(device: Camera): void {
    this.selectedCamera = device;
    this.qrScanner.setCamera(this.selectedCamera.id);
    localStorage.setItem('cameraId', this.selectedCamera.id);
  }

  public scanSuccess(code: string): void {
    this.onScanSuccess.emit(code);
  }

  ngOnDestroy() {
    this.qrScanner.destroy();
  }
}
