import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import {
  DxButtonModule,
  DxContextMenuModule,
  DxToolbarModule,
} from 'devextreme-angular';
import { branch, revision, version } from '../version';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './core/shared/shared.module';
import { AppComponent } from './app.component';
import { SplashScreenService } from './core/splash-screen/splash-screen.service';
import {
  CoreConfigService,
  CoreConfigurationModule,
} from '@ideenherd/core/configuration';
import { environment } from '../environments/environment';
import { IdeenherdLibraryModule } from '@ideenherd';
import { CoreDataModule } from '@ideenherd/core/data';

if (environment.production) {
  Bugsnag.start({
    apiKey: environment.bugsnagApiKey,
    appVersion: version,
    metadata: {
      version: { version, branch, revision }
    },
    enabledReleaseStages: ['development', 'staging', 'production'],
    releaseStage: environment.releaseStage,
  });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    SharedModule,
    BrowserModule,
    DxToolbarModule,
    AppRoutingModule,
    DxContextMenuModule,
    CoreConfigurationModule,
    CoreDataModule,
    DxButtonModule,
    IdeenherdLibraryModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigurationFactory,
      deps: [CoreConfigService],
      multi: true,
    },
    SplashScreenService,
    CoreConfigService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    console.group('SI-nect Web App');
    console.debug(
      `Version ${version}-${branch}+sha.${revision}`
    );
    console.groupEnd();
  }
}

export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

export function ConfigurationFactory(globalConfig: CoreConfigService) {
  return () => {
    return new Promise((resolve) => {
      globalConfig.loadConfiguration(environment.API_URL).then((config) => {
        resolve(config);
      });
    });
  };
}
