import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DxToastModule } from 'devextreme-angular';
import { IdeenherdLoaderComponent } from './ideenherd-loader/ideenherd-loader.component';
import { QrScannerComponent } from './qr-scanner/qr-scanner.component';
import { ToastComponent } from './toast/toast.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DxToastModule
  ],
  declarations: [
    IdeenherdLoaderComponent,
    QrScannerComponent,
    ToastComponent
  ],
  exports: [
    IdeenherdLoaderComponent,
    QrScannerComponent,
    ToastComponent
  ],
})
export class IdeenherdLibraryModule {
}
